
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import { cloneDeep } from "lodash";
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { parseTime, countryNoComList } from "@/utils";
import { certifiedList, setCertifiedStatus } from "@/api/request/player";

//组件
@Component({
  name: "CertifiedList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private countryList: any[] = [];
  private avatarList: string[] = [];
  private listLoading: boolean = false;

  //请求参数
  private listQuery: any = {
    //页码变量
    row: 20,
    page: 1,

    //临时变量
    userid_str: "",
    time: [] as any,

    //请求数据
    status: "",
    country: "",
    endformat: "",
    startformat: "",
    userid: undefined,
  };

  //审核状态
  private auditStatusList: any[] = [
    { status: "", name: "所有审核状态" },
    { status: "0", name: "未处理" },
    { status: "1", name: "已通过" },
    { status: "3", name: "拒绝" },
  ];

  //创建
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  // //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await certifiedList(this.getListQuery());

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //获取查询条件数据
  private getListQuery(): any {
    //数据赋值
    const data: any = GFunc.deepClone(this.listQuery);

    //用户ID
    data.userid = Number(data.userid_str);

    //时间数据
    data.time = GFunc.checkarray(data.time);
    data.endformat = DTCls.getEndTimeFormat(data.time[1]);
    data.startformat = DTCls.getStartTimeFormat(data.time[0]);

    //返回数据
    return data;
  }

  //打开头像
  private openAvatar(src: string): void {
    //数据赋值
    this.avatarList = [];
    this.avatarList.push(src);
  }

  //拒绝提现
  private confirmRejectCertified(): void {
    //变更提现审核
    this.handleCertifiedOp(this.certifiedData, 3, this.rejectCertifiedReason);

    //隐藏对话框
    this.rejectDialogVisible = false;
  }

  //获取时间
  private getTimeString(time: any): string {
    let str = parseTime(time);
    if (str === null) {
      str = "0000-00-00 00:00:00";
    }
    return str;
  }

  //处理拒绝提现
  private handleRejectCertified(row: any): void {
    //数据赋值
    this.rejectCertifiedReason = "";
    this.certifiedData = cloneDeep(row);

    //显示对话框
    this.rejectDialogVisible = true;
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //获取审核状态名
  private getAuditStatusName(status: number): string {
    for (let idx = 1; idx < this.auditStatusList.length; idx++) {
      const data = this.auditStatusList[idx];
      if (GFunc.checkint(data.status) === status) {
        return data.name;
      }
    }
    return "--";
  }

  //变更提现审核
  private handleCertifiedOp(row: any, status: any, reason?: string): void {
    //数据赋值
    const msg = status === 1 ? "您正在【通过】该用户提起的认证请求，您确定操作吗？" : "您正在【拒绝】该用户提起的认证请求，您确定操作吗？";

    //显示提示
    MessageBox.confirm(msg, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        //修改状态
        this.trySetCertifiedStatus(row, status, reason);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //修改状态
  private async trySetCertifiedStatus(row: any, status: any, reason?: string) {
    //数据赋值
    const index = this.list.findIndex((v) => v.id === row.id);
    reason = reason === undefined || reason === null ? "" : reason;
    const reqParams = { id: row.id, status: status, memo: reason, userid: row.userid };

    //显示等待
    this.listLoading = true;

    //请求
    await setCertifiedStatus(reqParams);

    //获取列表
    this.getList();

    //显示提示
    Message({
      message: "操作成功",
      type: "success",
      duration: 5 * 1000,
    });
  }

  //----------------------------------------- 拒绝原因 -----------------------------------------
  //定义变量
  private certifiedData: any = {};
  private rejectCertifiedReason: string = "";
  private rejectDialogVisible: boolean = false;
}
